import React, { useState, useEffect } from "react";

import { Flex, Box } from 'rebass'

import { AnchorButton, Button, Card, Tag, TextArea, InputGroup } from "@blueprintjs/core";

import {
    useHistory,
    useLocation
  } from "react-router-dom";


import levels from '../../utils/levels.json'
import subjects from '../../utils/subjects.json'

import axios from 'axios'

import {Anchorme} from 'react-anchorme'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// const devToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU3MjY2MTVBLUJGMUQtNDEzOS1BNkQwLUFEODVCQkY3Q0NERiIsImx2bCI6Im1lbl9hdGFzIiwiZW1haWwiOiJlbWlyYW51YXJAZ21haWwuY29tIiwiZnVsbG5hbWUiOiJOb29yIEVtaXIgQW51YXIiLCJhY2NvdW50dHlwZSI6InN0dWRlbnQiLCJpYXQiOjE2MzA3ODg3NTF9.GMJO4uazgEzoo-5_dSKVoxwxOEIDQsStmDu93PfE_ik'

// const questionid = '42092A7C-A7BE-439B-A9C5-7329DDA6C2F5'
let id = ''

const devToken = window.localStorage.getItem('access')

try {
    id = parseJwt(devToken).id;
    axios.defaults.headers.common['Authorization'] = devToken;
} catch (e) {
    id =''
}



const Answer = props => {

    const history = useHistory();

    let query = useQuery();

    const [question, setQuestion] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [yourId, setYourId] = useState('')
    const [ownerId, setOwnerId] = useState('')
    const [fullView, setFullView] = useState(false)
    const [fullViewUrl, setFullViewUrl] = useState(null)

    const [conv, setConv] = useState([])
    const [addCommentView, setAddCommentView] = useState(false)

    const [newComment, setNewComment] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const [questionid, setQuestionid] = useState('')


    useEffect(() => {

        // const questionid =  query.get('questionid')
        let q_id = props.questionid

        if(q_id) {

            setQuestionid(q_id)

            axios('https://ulangkajiapis.azurewebsites.net/questions/web?questionid=' + q_id)
            .then(res => {

                setOwnerId(res.data[0].userid)
                setQuestion(res.data[0])
    
            })
            .catch(err => console.log('error fetching latest questions'))
    
            axios('https://ulangkajiapis.azurewebsites.net/answers/web?questionid=' + q_id)
            .then(res => {
    
                setAnswer(res.data[0])
    
            })
            .catch(err => console.log('error fetching latest questions'))

            axios('https://ulangkajiapis.azurewebsites.net/conversations?questionid=' + q_id)
            .then(res => {

                
    
                res = res.data
    
                res = res.map(conv => {
                    if(conv.userid == id) {
                        conv.fullname = 'Anda'
                    }
    
                    return conv
                })
    
                console.log(res)
    
                setConv(res)
    
            })
            .catch(err => console.log('error fetching latest questions'))
        }


    }, [])

    function fetchConversation() {
        axios('https://ulangkajiapis.azurewebsites.net/conversations?questionid=' + questionid)
        .then(res => {

            res = res.data

            res = res.map(conv => {
                if(conv.userid == id) {
                    conv.fullname = 'Anda'
                }

                return conv
            })

            console.log(res)

            setConv(res)

        })
        .catch(err => console.log('error fetching latest questions'))

    }

    function submitMsg() {

        setIsLoading(true)

        const payload = {
            userid: id,
            body: newComment,
            questionid: questionid
        }

        axios.post('https://ulangkajiapis.azurewebsites.net/conversations', payload).then(() => {
            
            // setAddCommentView(false)
            // setIsLoading(false)

            setConv([...conv, JSON.parse(JSON.stringify({
                fullname: 'Anda',
                body: newComment,
                tstamp: new Date().getTime()
            }))])

            setNewComment('')

        }).catch(err => {
            alert('Pertanyaan gagal. Cuba sekali lagi')
            // setIsLoading(false)

        }).finally(() => {
            setIsLoading(false)
        })
    }

    function onEnter(event) {
        if(event.key == 'Enter') {
            submitMsg()
        }
    }




    return (
    <>
        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.25 ]}>

            </Box>
            <Box width={[ 1, 1,1,0.5 ]}>

                <div style={{paddingLeft: '5vw', paddingRight: '5vw', paddingTop: '5vh', textAlign:'left'}}>
                    {/* <img src={logo} className={'mainlogo'} /> */}

                    <AnchorButton large={true} minimal={true} icon={'arrow-down'} onClick={() => props.onClose() } />

                    <br />

                    <br/>

                    {question ?      
                                    
                        <Card elevation={2}>
                            <h1>Soalan</h1>
                            {question.hasPhoto ? <img src={question.img_url} style={{width: '100%'}}/> : <></>}
                            <p>{question.body}</p>
                            <Tag large={true} style={{margin: '4px'}}>{subjects[question.subj]}</Tag>
                            <Tag large={true} style={{margin: '4px'}}>{levels[question.lvl]}</Tag>
                        </Card> 
                    
                    : 
                    
                    <></>}

                    <br />

                    {answer ? 

                        <Card elevation={2}>
                            <h1>Jawapan</h1>
                            {answer.hasPhoto ? <img src={answer.img_url} style={{width: '100%'}} /> : <></>}
                            <h3>{answer.title}</h3>
                            <p>
                                <Anchorme target="_blank" style={{color: 'blue'}}>{answer.summary}</Anchorme>
                            </p>
                            <br />
                            <br />
                            <h6>{new Date(answer.tstamp).toDateString()}</h6>
                        </Card>

                        :

                        <></>
                
                    }

                    <br />
                    <br />

                    {conv.map(msg => {
                        return(
                            <div style={msg.fullname == 'Anda' ?  outContainer : inContainer}>
                                <div style={msg.fullname == 'Anda' ?  msgOut : msgIn}>
                                    {msg.body}
                                </div>
                            </div>
                        )
                    })}

                    
                    <br />
                    <br />

                    {/* <TextArea 
                        style={inputBox} 
                        rows="1" 
                        placeholder="Type Message" 
                        value={newComment} 
                        onChange={(e) => {setNewComment(e.target.value)}} 
                        onKeyPress={(e) => {onEnter(e)}}
                        
                        
                        /> */}

                    <InputGroup 
                        value={newComment} 
                        rightElement={newComment == '' || newComment == null ? <></> : <Button minimal={true} large={true} icon={'send-message'} loading={isLoading} onClick={() => submitMsg()} />}
                        onChange={(e) => {setNewComment(e.target.value)}} 
                        onKeyPress={(e) => {onEnter(e)}}
                        placeholder="Taip Mesej" 
                        // style={{
                        //     height: '48px'
                        // }}
                    />
                    <br />
                    <br />

                </div>


            </Box>

        </Flex>

    </>
    );
}

const inputBox = {

    width:'100%',
    borderRadius:'3px',
    resize:'none',
    fontSize: '1em',
    padding:'12px'

}

const inContainer = {
    textAlign: "left",
    paddingRight: "20%"
}

const outContainer = {
    textAlign: "right",
    paddingLeft: "20%"
}

const msgIn = {
    backgroundColor:'#e8e8e8',
    marginBottom:'2.5%',
    padding:'3.5%',
    borderRadius:'5px 5px 5px 0px',
    display:'inline-flex',
    fontSize:'1em',
}

const msgOut = {
    backgroundColor:'#29b7ca',
    marginBottom:'2.5%',
    padding:'3.5%',
    borderRadius:'5px 5px 0px 5px',
    display:'inline-flex',
    color:'white',
    fontSize:'1em',
}

export default Answer