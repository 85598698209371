import React, {useState, useEffect} from 'react'
import {Flex, Box} from 'rebass'
import { H1, Card, Elevation, Callout, InputGroup, H5, Button } from '@blueprintjs/core'
import logo from '../img/logo.png'

import {
    useLocation,
    useHistory
  } from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Reset = props => {

    let query = useQuery();
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    function submit() {

        setIsLoading(true)

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': query.get('t')
            },
            body: JSON.stringify({
                b: password
            })
        }

        fetch('https://ulangkajiapis.azurewebsites.net/auth/reset', config).then((res) => res.status).then(status => {
            // console.log(status === 401)
            if(status !== 200) {
                alert('Permintaan gagal. Sila cuba sekali lagi.')
            } else {
                alert('Password berjaya ditukar. Sila log masuk semula dalam aplikasi.')
                history.push('/')
            }

        }).catch(err => {
            
        }).finally(() => setIsLoading(false)) 

    }
    return(
        <>
        <Flex flexWrap={'wrap'}>
            <Box width={[0, 0.33]} order={[1, 0]}>

            </Box>
            <Box width={[1, 0.33]} order={[0, 1]}>
                {/* <h1>Password Reset Page</h1> */}
                <img src={logo} style={{width: '100%'}} />

                <br />

                <div style={{padding: '24px'}}>
                    <h3>Reset Password Anda</h3> 
                    
                    <Card>

                        <h4>Password baru</h4>
                        <InputGroup id="text-input" placeholder="Password" type={'password'} value={password} onChange={e => setPassword(e.target.value)}/>
                        
                        <br />
                        
                        <h4>Sahkan password baru</h4>
                        <InputGroup id="text-input" placeholder="Sahkan Password" type={'password'} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        <br />
                        <Button text={'Hantar'} disabled={password == '' || confirmPassword == '' || password != confirmPassword} loading={isLoading} fill={true} onClick={() => {submit()}}/>
                        <br />
                    </Card>
                </div>

                
            </Box>
        </Flex>
           
        
        </>
    )
}

export default Reset