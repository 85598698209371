import React from "react";

import { Flex, Box } from 'rebass'

import { Button } from "@blueprintjs/core";

import {
    useHistory
  } from "react-router-dom";

import logo from '../img/logo.png'

const Webend = props => {

    const history = useHistory();

    return (
    <>
        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.25 ]}>

            </Box>
            <Box width={[ 1, 1,1,0.5 ]}>

                <div style={{paddingLeft: '5vw', paddingTop: '5vh', paddingRight: '5vw', textAlign:'center'}}>
                    <div style={{
                        paddingRight: '0vw'
                    }}>

                        <h1 style={{marginTop: '6px', marginBottom: '12px', color:'black'}}>
                            Soalan telah Diterima
                        </h1>

                        <h3>
                            Tutor kami akan menjawabnya secepat mungkin. Tunggu emel ya!
                        </h3>

                        <Button text={'Kembali ke Paparan Utama'} intent={'primary'} minimal={false} large={true} onClick={() => history.push('/')}/>
                    </div>

                    <div>
                        <img src={logo} className={'mainlogo'} />
                    </div>

                </div>

            </Box>

        </Flex>

    </>
    );
}

export default Webend