import React, {useState, useEffect} from "react";

import { Flex, Box } from 'rebass'

import { Button, Drawer, Card, InputGroup, AnchorButton, Tag } from "@blueprintjs/core";

import axios from 'axios'

import {
    useHistory
  } from "react-router-dom";

import logo from '../img/logo.png'
import ambience1 from '../img/student_photo_1.jpg'

import icon1 from '../img/icons/camera-3-line.png'
import icon2 from '../img/icons/question-line.png'
import icon3 from '../img/icons/discuss-line.png'
import useDebounce from '../utils/debounce.js'

import levels from '../utils/levels.json'
import subjects from '../utils/subjects.json'

const Search = props => {

    const history = useHistory();

    const [answers, setAnswers] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [page, setPage] = useState(0)

    const [filteredSearchPage, setFilteredSearchPage] = useState(0)

    const [str, setStr] = useState('')

    const [noMore, setNoMore] = useState(false)

    const debounceSearch = useDebounce(str, 500)

    useEffect(() => {

        axios.get('https://ulangkajiapis.azurewebsites.net/answers/search').then(res => {
            setAnswers(res.data)
        }).catch(err => {

        })

    }, [])

    useEffect(() => {

            filteredSearch()

    }, [debounceSearch])

    function nextPage() {

        let addPage = page + 1

        axios.get('https://ulangkajiapis.azurewebsites.net' + '/answers/search?pg=' + addPage).then(res => {
            setPage(addPage)
            setAnswers([...answers, ...res.data])

            if(res.data.length < 10) {
                setNoMore(true)
            }

        })

    }

    function filteredSearch(nxt = false) {

        if(filteredSearchPage == 0) setAnswers([])

        setIsLoading(true)

        axios.get('https://ulangkajiapis.azurewebsites.net' + '/search', {
            params: {
                  str: str == '' ? null: str,
                  pg: filteredSearchPage  
            }
        }).then(res => {

            if(filteredSearchPage > 0) {
                setAnswers([...answers, ...res.data])
            } else {
                setAnswers(res.data)
            }

            if(nxt) {
                setFilteredSearchPage(filteredSearchPage + 1)
            }

        }).finally(() => setIsLoading(false))

    }

    return (
    <>
        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.25 ]}>

            </Box>
            <Box width={[ 1, 1,1,0.5 ]}>

                <div style={{paddingLeft: '5vw', paddingRight: '5vw', paddingTop: '5vh'}}>
                    {/* <div>
                        <img src={logo} className={'mainlogo'} />
                    </div> */}

                    <a href={'/'} target={'_self'}>
                        <img src={logo} className={'mainlogo'}  />
                    </a>

                    <div style={{
                        margin: '16px'
                    }}>
                        <InputGroup
                            leftIcon={'search'}
                            large={true}
                            placeholder="Cari Jawapan"
                            value={str}
                            onChange={(e) => setStr(e.target.value)}
                        />
                    </div>

                    <Button 
                        text={<h2><b>Tanya soalan Baru?</b></h2>} 
                        intent={'primary'} 
                        minimal={true}
                        large={false} 
                        fill={false} 
                        onClick={() => history.push('/web')}
                    
                    />

                    {answers.map(answer => {
                        return (
                            <a href={'/answer?questionid=' +  answer.questionid} target={'_blank'} style={{textDecoration: 'none'}}>
                                <Card 
                                    elevation={2} 
                                    style={{margin: '16px'}} 
                                    interactive={true} 
                                    // onClick={() => history.push({
                                    //     pathname: '/answer',
                                    //     search: '?questionid=' + answer.questionid,
                                    // })}
                                    >
                                        
                                    <h2>{answer.title}</h2>
                                    <p style={{color: 'black'}}>{answer.summary}</p>
                                    <Tag large={true} style={{margin: '4px'}}>{subjects[answer.subj]}</Tag>
                                    <Tag large={true} style={{margin: '4px'}}>{levels[answer.lvl]}</Tag>
                                    {/* <h6>{new Date(answer.tstamp).toDateString()}</h6> */}
                                </Card>
                            </a>
                        )
                    })}

                    <br />

                    {answers.length >= 10 && !noMore ?
                    
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <AnchorButton large={true} loading={isLoading} text={'Lagi'} onClick={() => {nextPage()}} />
                        </div>

                        :

                        <></>
                    }



                    <br />

                    <br />

                </div>

            </Box>

        </Flex>

    </>
    );
}

export default Search