import React, { useEffect, useState } from "react";

import {
    useHistory
  } from "react-router-dom";

import { Flex, Box } from 'rebass'

import axios from 'axios'

import { Button, HTMLSelect, InputGroup, TextArea } from "@blueprintjs/core";

import logo from '../img/logo.png'
import ambience1 from '../img/student_photo_1.jpg'

import icon1 from '../img/icons/camera-3-line.png'
import icon2 from '../img/icons/question-line.png'
import icon3 from '../img/icons/discuss-line.png'

import placeholder from '../img/snap-photo.png'

import levels from '../utils/levels.json'
import subjects from '../utils/subjects.json'

const Webask = props => {

    const history = useHistory() 

    const [subject, setSubject] = useState('addmath')
    const [level, setLevel] = useState('men_atas')
    const [image, setImage] = useState('')
    const [isEmailValid, setEmailValid] = useState(false)
    const [body, setBody] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [showPlaceholder, setShowPlaceholder] = useState(true)
    const [preview, setPreview] = useState('')

    useEffect(() => {
        
    }, [])

    function handleFileChange(e) {
        const {target} = e;
        const {files} = target;
    
        if (files && files[0]) {
            var reader = new FileReader();
            
            // reader.onloadstart = () => {
            //     this.setState({loading: true});
            // }
    
            reader.onload = event => {
                // this.setState({
                //     data: event.target.result,
                //     loading: false
                // });
                

                console.log(event.target.result)

                setPreview(event.target.result)
                setShowPlaceholder(false)
            };
        
            reader.readAsDataURL(files[0]);
        }
    }

    function updateEmail(em) {
        setEmailValid(validateEmail(em))
        setEmail(em)
    }

    function validateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return true
        }
        
        return false
    }

    function submitQuestion() {
        const payload = {
            imgData: preview,
            lvl: level,
            subj: subject,
            body: body,
            hasPhoto: !showPlaceholder,
            channel: 'web',
            email: email
        }

        // const localhost = 'http://localhost:3001/questions/web'

        const deployed = 'https://ulangkajiapis.azurewebsites.net/questions/web'

        // console.log(payload)

        setIsLoading(true)

        axios.post(deployed, payload).then(() => {
            setIsLoading(false)
            alert('Soalan berjaya ditanya!')
            history.push('/end')

        }).catch(err => {
            setIsLoading(false)
            alert('Penghantaran gagal. Sila cuba sekali lagi')
        })

    }

        

    return (
    <>
        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.3 ]} style={{
                // backgroundImage: `url(${ambience1})`,
                // backgroundPosition:'left top',
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // minHeight: '80vh'
                
            }}>

            </Box>
            <Box width={[ 1, 1,1,0.4 ]}>

                <br />

                <div style={{padding: '5vw'}}>

                    <div>

                        <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                            Tekan gambar untuk melancarkan kamera
                        </h2>



                        <label 
                            htmlFor="car"
                            style={{
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            
                        >
                            <input style={{
                                width:'1px',
                                height: '1px',
                                opacity: 0,
                                overflow: 'hidden',
                                zIndex: -1
                            }}           
                                id="car"
                                type="file"
                                accept="image/*"
                                capture="camera"
                                onChange={e => handleFileChange(e)}
                            />
                            {showPlaceholder ? <img src={placeholder}style={{width: '100%'}} /> : <img src={preview} style={{width: '100vw'}} />}
                        </label>

                        

                        
                    </div>

                    <div style={{paddingTop: '2vh'}}>

                    <br />

                    <div>

                        <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                            Soalan
                        </h2>

                        <TextArea
                            growVertically={true}
                            large={true}
                            fill={true}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            


                        />
                    </div>
                        


                        <br />

                        <div>

                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Subjek
                            </h2>
                            <HTMLSelect fill={true} value={subject} onChange={e => setSubject(e.target.value)}>
                                
                                {Object.keys(subjects).map(subject => {
                                    return <option value={subject}>{subjects[subject]}</option>
                                })}
                                
                                
                            </HTMLSelect>
                        </div>

                        <br />

                        <div>

                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Tahap
                            </h2>
                            <HTMLSelect fill={true} value={level} onChange={e => setLevel(e.target.value)}>
                                {Object.keys(levels).map(level => {
                                    return <option value={level}>{levels[level]}</option>
                                })}
                            </HTMLSelect>
                        </div>

                        <br />

                        <div>

                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Emel
                            </h2>

                            <InputGroup
                                large={true}
                                placeholder="Alamat Emel"
                                type={'email'}
                                value={email}
                                onChange={e => {
                                    updateEmail(e.target.value)
                                }}

                            />
                        </div>

                        <br />

                        <Button 
                            disabled={!isEmailValid || subject == '' || level == '' || body == ''}
                            loading={isLoading}
                            fill={true} 
                            text={<h3><b>Hantar</b></h3>} 
                            onClick={() => submitQuestion()}
                            intent={'primary'} />
                        <Button fill={true} large={true}  text={<h3><b>Laman Utama</b></h3>} minimal={true} onClick={() => history.push('/')} />
                        
                        <div>
                            <img src={logo} className={'mainlogo'} />
                        </div>
                    </div>

                </div>

            </Box>

            <Box width={[ 1, 1,1,0.2 ]} style={{
                // backgroundImage: `url(${ambience1})`,
                // backgroundPosition:'left top',
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // minHeight: '80vh'
                
            }}>

            </Box>
        </Flex>

    </>
    );
}

export default Webask