import { H1, Card, Elevation, Callout, InputGroup, H5, Button, H2, Tag } from '@blueprintjs/core'
import React, {useState, useEffect, useRef} from 'react'
import Slider from "react-slick"
import axios from 'axios'
import { Flex, Box } from 'rebass'

import {
    useHistory
  } from "react-router-dom";


import image1 from '../img/learning.png'
import appstore from '../img/playstore_button.png'
import onweb from '../img/useonweb_icon.png'
import logo from '../img/logo.png'
import phone from '../img/pixel2xl.png'
import experts from '../img/experts.png'

import ss1 from '../img/image3.png'
import ss2 from '../img/image2.png'
import ss3 from '../img/image1.png'
import ss4 from '../img/image0.png'
import ss5 from '../img/image4.png'

import student1 from '../img/students/school_kid.png'
import student2 from '../img/students/school_kid_1.jpg'
import student3 from '../img/students/school_kid_2.jpg'
import student4 from '../img/students/school_kid_3.jpg'

import kids2 from '../img/students/kids2.jpg'
import kids3 from '../img/students/kids3.jpg'
import kids4 from '../img/students/kids4.jpg'

import icon1 from '../img/icons/camera-3-line.png'
import icon2 from '../img/icons/question-line.png'
import icon3 from '../img/icons/discuss-line.png'

import ambience1 from '../img/student_photo_1.jpg'
import ambience2 from '../img/student_photo_3.jpg'
import ambience3 from '../img/student_photo_4.jpg'

import levels from '../utils/levels.json'
import subjects from '../utils/subjects.json'

const SNAPS = [ss2, ss3, ss4]

var settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

const Main = props => {

    const history = useHistory() 

    const [submitted, setIsSubmitted] = useState(false)
    const [idx, setIdx] = useState(0)
    const [answers, setAnswers] = useState([])
    const slider = useRef()

    useEffect(() => {

        axios.get('https://ulangkajiapis.azurewebsites.net/answers/search').then(res => {

            let ans = res.data

            if(ans.length > 3) {
                setAnswers(ans.slice(0,3))
            } else {
                setAnswers(ans)
            }
            
        }).catch(err => {

        })

    }, [])


    return(
        <>
            <Flex>
                <Box width={[0.7, 0.7, 0.7, 0.5]}>
                    <div style={{paddingLeft: '5vw'}}>
                        <img src={logo} style={{width: '100%'}} />
                    </div>
                </Box>
                <Box width={[0, 0, 0, 0.4]}/>
                <Box width={[0.3, 0.3, 0.3, 0.1]} style={{padding: '12px', paddingTop:'24px'}}>
                    
                    <Button 
                        text={'Log Masuk'}
                        fill={true}
                        intent={'primary'}
                        large={true}
                        onClick={() => history.push('/login')}
                    />
                </Box>
            </Flex>
            <Flex flexWrap='wrap' style={{
                    backgroundImage: `url(${ambience1})`,
                    backgroundPosition:'left top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    minHeight: '80vh'
                    
                }}>
                <Box width={[ 1, 1,1,0.6 ]}>

                    <div style={{paddingLeft: '2.5vw', paddingTop: '5vh', paddingRight: '2.5vw'}}>
                        {/* <h1 className={'headline'} style={{marginTop: '12px', marginBottom: '18px', color: '#06B6D4'}}>
                            <b>Belajar Dengan Mudah!</b>
                        </h1>

                        <div style={{
                            paddingRight: '0vw'
                        }}>

                            <h2 style={{marginTop: '6px', marginBottom: '48px', color:'#ffffff'}}>
                                Buntu belajar sendiri? Snap, Tanya dan Belajar!
                            </h2>
                        </div> */}

                        {/* <div style={{textAlign: 'left'}}>
                            <Flex>
                                <Box>
                                    <a href={'https://ulangkaji.online'} target={'_blank'}>
                                        <img src={appstore} style={{height: '10vh', marginTop: '24px', marginBottom: '10px', padding: '12px'}} />
                                    </a>
                                </Box>
                                <Box >
                                    <a href={'/web'} target={'_blank'}>
                                        <img src={onweb} style={{height: '10vh', marginTop: '24px', marginBottom: '10px', padding: '12px'}} />
                                    </a>
                                </Box>
                            </Flex>
                            <h6 style={{color: 'white', margin: '0px'}}>Apple App Store dalam masa terdekat</h6>
                        </div> */}

                        {/* <div style={{textAlign: 'left'}}>
                            <a href={'https://ulangkaji.online'} target={'_blank'}>
                                <img src={appstore} style={{height: '10vh', marginTop: '24px', marginBottom: '24px'}} />
                            </a>
                        </div>

                        <div style={{
                            paddingRight: '0vw'
                        }}>

                            <h2 style={{marginTop: '12px', marginBottom: '48px', backgroundColor:'#ffffff80'}}>
                                Buntu belajar sendirian? Belajar daripada golongan professional, guru - guru dan pelajar cemerlang dari seluruh negara!
                            </h2>
                        </div> */}

                        <h2 style={{
                            marginTop: '48px', 
                            marginBottom: '8px', 
                            color: '#ffffff'
                            }}

                            >
                            <b>Buntu ketika ulangkaji?</b>
                        </h2>


                        <h2 className={'headline'} style={{
                            marginTop: '0px', 
                            marginBottom: '18px', 
                            color: '#ffffff'
                            }}

                            >
                            <b>Tanya Dengan Mudah!</b>
                        </h2>

                        <br />

                        <br />

                        <Button 
                            text={<h2><b>Mula Bertanya</b></h2>} 
                            intent={'primary'} 
                            large={true} 
                            fill={true} 
                            onClick={() => history.push('/web')}
                            
                            />
                        
                        <br />
                        
                        <h5 style={{color: 'white'}}>Versi web kini sudah boleh digunakan. App akan menyusul</h5>
                    </div>

                </Box>
                <Box width={[ 1, 1,1,0.4 ]}  style={{
                    textAlign: 'center',
                    padding: '5vh',
                    // backgroundImage: `url(${ambience1})`,
                    // backgroundPosition:'left bottom',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'cover',
                    // minHeight: '90vh'
                    
                }}>
                    <div style={{marginTop: '5vh', width: '100%', justifyContent: 'center', textAlign: 'center'}} className={'hideonmobile'}>


                        <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '60vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center'
                            
                        }}>
                            <img src={ss1} style={{position:'relative', height: '42vh', top: '9vh', left: '0.5vh', zIndex: 1}}/>
                        </div>

                    </div>
                    
                </Box>
            </Flex>

            {/* <Flex>
                <Box 
                    width={[1]}
                    style={{
                        textAlign: 'center',
                        padding: '5vh',
                        paddingLeft: '15vw',
                        paddingRight: '15vw',
                        backgroundColor: '#e5e5e5'
                    }}>
                        <h1>
                            <b>
                                Sumber bertanya adalah teras pendidikan. <span style={{color: '#22D3EE'}}>Ulangkaji</span> <span style={{color: '#06b6d4'}}>Online</span> memastikan semua pelajar mempunyainya
                            </b>
                        </h1>
                        
                </Box>
            </Flex> */}
            {/* <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap'}}>
                {answers.map(answer => {
                    return(
                        <a href={'/answer?questionid=' +  answer.questionid} target={'_blank'} style={{textDecoration: 'none'}}>
                            <Card 
                                elevation={2} 
                                style={{margin: '16px', display: 'inline-block'}} 
                                interactive={true} >
                                    
                                <h2>{answer.title}</h2>
                                <p style={{color: 'black', whiteSpace: 'pre-wrap'}}>{answer.summary}</p>
                            </Card>
                        </a>
                    )
                })}
            </div> */}

            <Flex style={{backgroundColor: '#e5e5e5'}} flexWrap='wrap'>
                <Box style={{textAlign: 'center', paddingTop: '10vh'}} width={[ 1, 1,1,0.25 ]}>

                    <h4>Lihat pertanyaan terkini</h4>
                    <a
                        href={'/search'}
                        target={'_blank'}
                    >
                        <h2>Cari Jawapan</h2>
                    </a>
                </Box>
                {answers.map(answer => {
                    return(
                        <Box style={{padding: '12px'}} width={[ 1, 1,1,0.25 ]}>
                            <a href={'/answer?questionid=' +  answer.questionid} target={'_blank'} style={{textDecoration: 'none'}}>
                                <Card 
                                    elevation={2} 
                                    style={{margin: '16px', height: '40vh'}} 
                                    interactive={true} 
                                    // onClick={() => history.push({
                                    //     pathname: '/answer',
                                    //     search: '?questionid=' + answer.questionid,
                                    // })}
                                    >
                                        
                                    <h2>{answer.title}</h2>
                                    <p style={{color: 'black'}}>{answer.summary}</p>
                                    <Tag large={true} style={{margin: '4px'}}>{subjects[answer.subj]}</Tag>
                                    <Tag large={true} style={{margin: '4px'}}>{levels[answer.lvl]}</Tag>
                                    <h6>{new Date(answer.tstamp).toDateString()}</h6>
                                </Card>
                            </a>
                        </Box>
                    )
                })}

            </Flex>

            <Flex>
                <Box 
                    width={[1]}
                    style={{
                        textAlign: 'center',
                        padding: '5vh',
                        paddingLeft: '15vw',
                        paddingRight: '15vw',
                        backgroundColor: '#e5e5e5'
                    }}>
                        <h1>
                            <b>
                                Sumber bertanya adalah teras pendidikan. <span style={{color: '#22D3EE'}}>Ulangkaji</span> <span style={{color: '#06b6d4'}}>Online</span> memastikan semua pelajar mempunyainya
                            </b>
                        </h1>
                        
                </Box>
            </Flex>

            <Flex flexWrap='wrap' className={'paddingAdaptive'}  style={{ backgroundColor:'#FCFCFC' }}>

                <Box width={[1,.5]} order={[0, 0]} style={{
                    // backgroundImage: `url(${experts})`,
                    // backgroundPosition:'left bottom',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: '75% auto',
                    // minHeight: '100vh'
                    
                }}>
                    <div style={{paddingTop: '5vh'}}>
                        


                        <br />

                        <div style={{
                            paddingLeft: '7vw',
                            paddingRight: '7vw',
                            paddingTop: '10vh'
                        }}>

                            <img src={icon1} style={{width: '48px', color: '#22D3EE'}} />

                            <h1 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4', fontSize: '48px'}}>
                                Snap
                            </h1>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Buntu ketika ulangkaji? Tidak dapat belajar berkumpulan? Tiada bantuan di rumah? <br /><br /> Jangan Bimbang. Download Ulangkaji Online, snap sahaja soalan anda
                            </p>
                        </div>



                    </div>

                </Box>
                <Box width={[ 1, 0.5 ]} order={[1, 1]} style={{
                    paddingTop: '10vh',
                    paddingLeft: '7vh',
                    paddingRight: '7vh',
                    height: '100vh'
                    
                }}>

                    <div style={{marginTop: '5vh', width: '100%', justifyContent: 'center', textAlign: 'center'}}>

                        <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '80vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center'
                            
                        }}>
                            <img src={ss2} style={{position:'relative', height: '57vh', top: '12vh', left: '0.5vh', zIndex: 1}}/>
                        </div>

                    </div>
                    
                </Box>
            </Flex>

            <Flex flexWrap='wrap' className={'paddingAdaptive'}  style={{ backgroundColor:'#FFFFFF' }}>

                <Box width={[1,.5]} order={[0, 1]} style={{
                    // backgroundImage: `url(${experts})`,
                    // backgroundPosition:'left bottom',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: '75% auto',
                    // minHeight: '100vh'
                    
                }}>
                    <div style={{paddingTop: '5vh'}}>
                        


                        <br />

                        <div style={{
                            paddingLeft: '5vw',
                            paddingRight: '5vw',
                            paddingTop: '10vh'
                        }}>

                            <img src={icon2} style={{width: '48px'}} />

                            <h1 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4', fontSize: '48px'}}>
                                Tanya
                            </h1>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Jelaskan kepada tutor dengan lebih terperinci soalan anda, supaya mereka dapat menjawabnya dengan berkesan untuk pemahaman anda
                            </p>
                        </div>



                    </div>

                </Box>
                <Box width={[ 1, 0.5 ]} order={[1, 0]} style={{
                    paddingTop: '10vh',
                    paddingLeft: '7vh',
                    paddingRight: '7vh',
                    height: '100vh'
                    
                }}>

                    <div style={{marginTop: '5vh', width: '100%', justifyContent: 'center', textAlign: 'center'}}>

                        <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '80vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center'
                            
                        }}>
                            <img src={ss3} style={{position:'relative', height: '57vh', top: '12vh', left: '0.5vh', zIndex: 1}}/>
                        </div>

                    </div>
                    
                </Box>
            </Flex>

            <Flex flexWrap='wrap' className={'paddingAdaptive'}  style={{ backgroundColor:'#FCFCFC' }}>

                <Box width={[1,.5]} order={[0, 0]} style={{
                    // backgroundImage: `url(${experts})`,
                    // backgroundPosition:'left bottom',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: '75% auto',
                    // minHeight: '100vh'
                    
                }}>
                    <div style={{paddingTop: '5vh'}}>
                        


                        <br />

                        <div style={{
                            paddingLeft: '5vw',
                            paddingRight: '5vw',
                            paddingTop: '10vh'
                        }}>

                            <img src={icon3} style={{width: '48px'}} />

                            <h1 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4', fontSize: '48px'}}>
                                Belajar
                            </h1>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Tutor akan menjawab soalan anda dengan tujuan mengajar, jadi semua jalan kerja, langkah - langkah akan diterangkan. Jika ada soalan, tanya sahaja!
                            </p>
                        </div>



                    </div>

                </Box>
                <Box width={[ 1, 0.5 ]} order={[1, 1]} style={{
                    paddingTop: '10vh',
                    paddingLeft: '7vh',
                    paddingRight: '7vh',
                    height: '100vh'
                    
                }}>

                    <div style={{marginTop: '5vh', width: '100%', justifyContent: 'center', textAlign: 'center'}}>

                        <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '80vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center'
                            
                        }}>
                            <img src={ss4} style={{position:'relative', height: '57vh', top: '12vh', left: '0.5vh', zIndex: 1}}/>
                        </div>

                    </div>
                    
                </Box>
            </Flex>



            {/* <Flex flexWrap='wrap' style={{backgroundColor:'#FCFCFC' }}>
                <Box 
                    width={[ 1, 0.4 ]} 
                    style={{
                        textAlign: 'center',
                        paddingTop: '5vh',
                        minHeight: '100vh'
                    }}
                    order={[1, 0]}
                    
                >

                    <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '80vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center',
                            zIndex: 1                            
                        }}>
                            <Slider ref={slider} {...settings} style={{height: '57vh', top: '12vh', left: '0.5vh', position:'relative', zIndex: 0}} >
                                {[0, 1, 2].map(idx => {
                                    return(
                                        <div style={{position:'absolute', height: '57vh', top: '12vh', left: '0.5vh', zIndex: 1}}>
                                            <img src={SNAPS[idx]} style={{height: '56vh', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}/>
                                        </div>
                                    )
                                })}
                            </Slider>
                            
                        </div>

                </Box>
                <Box width={[1, .5]} order={[0, 1]}>
                    <div className={'marginAdaptive'} style={{paddingTop: '5vh', }}>
                           
                        <h1 style={{fontSize: '48px', textAlign: 'left', lineHeight: '56px', marginTop: '12px', marginBottom: '12px', marginLeft: '0vw', color: '#06B6D4'}}>
                            <b>Tanya dengan lebih pantas</b>
                        </h1>
                        <h4>Bagaimana?</h4>
                        
                        <br />

                        <div style={{

                        }}>
                            <Flex flexWrap={'wrap'}>
                                <Box width={[1, .33]}>
                                <Flex flexWrap={'wrap'}>
                                <Box width={[.33, 1]}>
                                    <Card interactive={true} onMouseEnter={() => {setIdx(0)}} onClick={() => {slider.current.slickGoTo(0)}}>
                                        <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#22D3EE'}}>
                                            Snap
                                        </h2>
                                    </Card>
                                </Box>

                                <Box width={[.33, 1]}>
                                    <Card interactive={true} onMouseEnter={() => {setIdx(1)}}  onClick={() => {slider.current.slickGoTo(1)}}>
                                        <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#22D3EE'}}>
                                            Tanya
                                        </h2>

                                    </Card>
                                </Box>
                                <Box width={[.33, 1]}>
                                    <Card interactive={true} onMouseEnter={() => {setIdx(2)}}  onClick={() => {slider.current.slickGoTo(2)}}>
                                        <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#22D3EE'}}>
                                            Belajar
                                        </h2>

                                    </Card>
                                </Box>
                            </Flex>

                                </Box>
                            </Flex>

                        </div>

                    </div>

                </Box>

            </Flex> */}

            <Flex flexWrap='wrap' className={'paddingAdaptive'} >

                <Box width={[1,.6]} order={[0, 0]} style={{
                    backgroundImage: `url(${experts})`,
                    backgroundPosition:'left bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '75% auto',
                    minHeight: '100vh'
                    
                }} >
                    <div style={{
                        paddingTop: '7vh',
                        paddingLeft: '7vh'
                        
                        }}>
                           
                        <h1 style={{fontSize: '48px', textAlign: 'left', lineHeight: '56px', marginTop: '12px', marginBottom: '24px',  color: '#06B6D4'}}>
                            <b>Untuk Tutor</b>
                        </h1>

                        <br />

                        <div style={{
                            // paddingLeft: '10vw',
                            // paddingRight: '10vw'
                        }}>

                            <h1 style={{marginTop: '12px', marginBottom: '12px', color: '#22D3EE'}}>
                                Bantu Generasi Hadapan
                            </h1>
                            <p>
                                “Berat sama dipikul” - bersama kita memastikan ekuiti dalam pendidikan. 
                            </p>

                            <br />

                            <h1 style={{marginTop: '12px', marginBottom: '12px', color: '#22D3EE'}}>
                                Kerja Sebagai Tutor
                            </h1>
                            <p>
                                Potensi pekerjaan, membantu pelajar - pelajar daripada satu negara belajar!  Pada masa ini kami sedang menjalan fasa percubaan tertutup.
                            </p>
                            <p>
                                Dalam masa terdekat, kami akan membuka pengambilan tutor - tutor sepenuh masa dan juga separuh masa. 
                            </p>
                            <br />
                        </div>



                    </div>

                </Box>
                <Box width={[ 1, 0.4 ]} order={[1, 1]} style={{
                    paddingTop: '20vh',
                    paddingLeft: '5vh',
                    paddingRight: '5vh',
                    height: '100vh'
                    
                }}>

                    <Callout intent={'primary'} style={{ padding: '24px'}}>
                        <h1>Kriteria - Kriteria Tutor:</h1>
                        <p>
                            <ul>
                                <li>
                                    Keputusan cemerlang dalam subjek yang anda ingin ajar 
                                </li>
                                <li>
                                    Terbuka kepada pelajar - pelajar SPM, hingga ke golongan professional dan guru - guru
                                </li>
                                <li>
                                    Minat dalam mendidik dan membantu generasi baru
                                </li>
                                <li>
                                    Ingin memberi kelainan yang nyata dalam membantu pendidikan dalam norma baharu
                                </li>
                                <li>
                                    Mencari peluang untuk pendapatan tambahan 
                                </li>
                        </ul>

                        </p>
                        
                    </Callout>



                    {/* <div style={{marginTop: '5vh', width: '100%', justifyContent: 'center', textAlign: 'center'}}>

                        <div style={{
                            backgroundImage:`url(${phone})`,
                            height: '80vh',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center center',
                            textAlign:'center'
                            
                        }}>
                            <img src={ss5} style={{position:'relative', height: '57vh', top: '12vh', left: '0.5vh', zIndex: 1}}/>
                        </div>

                    </div> */}
                    
                </Box>
            </Flex>
            <Flex flexWrap='wrap' style={{minHeight: '100vh',  backgroundColor:'#FCFCFC' }}>
                <Box width={[1, 0.5]}>
                    <div style={{paddingLeft: '7vw', paddingTop: '7vh'}}>
                           
                        <h1 style={{fontSize: '42px', lineHeight: '56px', marginTop: '12px', marginBottom: '24px', color: '#06B6D4'}}>
                            <b>#KitaAjarKita</b>
                        </h1>

                        <div>
                            <br />
                            <h1>
                                Kenapa Ulangkaji didirikan? 
                            </h1>
                            <p>
                                Pandemik COVID-19 mendedahkan kepincangan dalam sistem pendidikan negara.
                            </p>
                            <p>
                                Dengan PdPr, jurang ekonomi akan lebih jelas. Pelajar yang lebih bernasib baik boleh mendapatkan khidmat tutor peribadi, perkakasan yang lebih berkualiti dan ibu-bapa/penjaga yang professional untuk membantu.
                            </p>
                            <p>
                            Terbalik pula dengan golongan yang kurang bernasib baik. Tidak semua mempunyai ibu-bapa/penjaga yang mampu membantu pelajar seluruhnya. Ini adalah jurang yang Ulangkaji harap untuk penuhi.
                            </p>
                            <p>
                            Seperti mana kita membantu dari segi kewangan, pelaburan masa untuk membantu sebanyak mungkin pelajar - pelajar adalah penting dalam memastikan kesamaan kualiti pendidikan.
                            </p>

                            <br />

                            <h2 style={{ color: '#06B6D4'}}>#KitaAjarKita</h2>
                        </div>



                    </div>

                </Box>
                <Box width={[1, 0.5]} style={{padding: '24px'}}>
                    <Flex>
                        <Box width={[0, 0.3]}>
                            {/* <img src={student2} style={{width: '100%', padding: '24px'}} /> */}
                        </Box>
                        <Box width={[1, 0.7]}>
                            <img src={kids2} style={{width: '100%', padding: '24px'}} />
                        </Box>
                    </Flex>
                    <Flex>
                        <Box width={0.4}>
                            <img src={kids3} style={{width: '100%', padding: '24px'}} />
                        </Box>
                        <Box width={[0.6, 0.5]}>
                            <img src={kids4} style={{width: '100%', padding: '24px'}} />
                        </Box>
                    </Flex>
                    <Flex>
                        <Box width={[0, 0.1]}>
                            {/* <img src={student2} style={{width: '100%', padding: '24px'}} /> */}
                        </Box>
                        <Box width={[1, 0.7]}>
                            <img src={student4} style={{width: '100%', padding: '24px'}} />
                        </Box>
                    </Flex>
                    
                    
                </Box>
            </Flex>

            <div style={{padding: '5%'}}>
                <hr />
            </div>

            <Flex flexWrap='wrap'>
                <Box width={[.5, 0.25]} order={[0, 1]} style={{paddingLeft: '10vw'}}>
                    <h2>Ulangkaji</h2>
                    <a href='/terma'><h3>Terma Penggunaan</h3></a>
                    <a href='/privasi'><h3>Polisi Privasi</h3></a>
                </Box>
                <Box width={[.5, 0.25]} order={[1, 2]} style={{paddingLeft: '10vw'}}>
                    <h2>Sosial</h2>
                    <a href='https://www.facebook.com/get.ulangkaji.online' target={'_blank'}><h3>Facebook</h3></a>
                    {/* <h3>Instagram</h3> */}
                </Box>
                <Box width={[1, 0.25]} order={[2, 0]} style={{textAlign: 'center'}}>
                    <img src={logo} style={{height: '10vh'}} />
                </Box>
                <Box width={[1, 0.25]} order={[3, 3]} style={{textAlign: 'center'}}>
                    {/* <img src={appstore} style={{height: '10vh'}} /> */}
                </Box>
            </Flex>

            <br />

            <div style={{textAlign: 'center', padding: '24px'}}>
                Buatan Malaysia
            </div>

            < br />
        </>
    )
}

export default Main