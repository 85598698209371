import React from 'react'

const About = props => {
    return(
        <>
        <h1>About</h1>
        
        </>
    )
}

export default About