import React, { useState, useEffect } from "react";

import { Flex, Box } from 'rebass'

import { AnchorButton, Button, Card, Tag } from "@blueprintjs/core";

import {
    useHistory,
    useLocation
  } from "react-router-dom";

import logo from '../img/logo.png'
import levels from '../utils/levels.json'
import subjects from '../utils/subjects.json'

import axios from 'axios'

import {Anchorme} from 'react-anchorme'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Answer = props => {

    const history = useHistory();

    let query = useQuery();

    const [question, setQuestion] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [yourId, setYourId] = useState('')
    const [ownerId, setOwnerId] = useState('')
    const [fullView, setFullView] = useState(false)
    const [fullViewUrl, setFullViewUrl] = useState(null)

    const [conv, setConv] = useState([])
    const [addCommentView, setAddCommentView] = useState(false)

    const [newComment, setNewComment] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        const questionid =  query.get('questionid')

        console.log(questionid)

        if(questionid) {
            axios('https://ulangkajiapis.azurewebsites.net/questions/web?questionid=' + questionid)
            .then(res => {

                setOwnerId(res.data[0].userid)
                setQuestion(res.data[0])
    
            })
            .catch(err => console.log('error fetching latest questions'))
    
            axios('https://ulangkajiapis.azurewebsites.net/answers/web?questionid=' + questionid)
            .then(res => {
    
                setAnswer(res.data[0])
    
            })
            .catch(err => console.log('error fetching latest questions'))
        }


    }, [])

    return (
    <>
        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.25 ]}>

            </Box>
            <Box width={[ 1, 1,1,0.5 ]}>

                <div style={{paddingLeft: '5vw', paddingRight: '5vw', paddingTop: '5vh', textAlign:'left'}}>
                    {/* <img src={logo} className={'mainlogo'} /> */}

                    <AnchorButton large={true} minimal={true} icon={'arrow-left'} onClick={() => history.push('search') } />

                    <br />

                    <br/>

                    {question ?      
                                    
                        <Card elevation={2}>
                            <h1>Soalan</h1>
                            {question.hasPhoto ? <img src={question.img_url} style={{width: '100%'}}/> : <></>}
                            <p>{question.body}</p>
                            <Tag large={true} style={{margin: '4px'}}>{subjects[question.subj]}</Tag>
                            <Tag large={true} style={{margin: '4px'}}>{levels[question.lvl]}</Tag>
                        </Card> 
                    
                    : 
                    
                    <></>}

                    <br />

                    {answer ? 

                        <Card elevation={2}>
                            <h1>Jawapan</h1>
                            {answer.hasPhoto ? <img src={answer.img_url} style={{width: '100%'}} /> : <></>}
                            <h3>{answer.title}</h3>
                            <p>
                                <Anchorme target="_blank" style={{color: 'blue'}}>{answer.summary}</Anchorme>
                            </p>
                            <br />
                            <br />
                            <h6>{new Date(answer.tstamp).toDateString()}</h6>
                        </Card>

                        :

                        <></>
                
                    }



                    <br />
                    <br />

                    <img src={logo} className={'mainlogo'} />

                    
                    <br />
                    <br />


                </div>


            </Box>

        </Flex>

    </>
    );
}

export default Answer