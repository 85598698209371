import React, {useState, useEffect} from 'react'
import {Flex, Box} from 'rebass'
import { H1, Card, Elevation, Callout, InputGroup, H5, Button, HTMLSelect } from '@blueprintjs/core'
import logo from '../../img/logo.png'
import doodle from '../../img/doodle.jpg'
import axios from 'axios'
import validateEmail from '../../utils/emailchecker'

import {
    useLocation,
    useHistory
  } from "react-router-dom";

import levels from '../../utils/levels.json'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Login = props => {

    let query = useQuery();
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [level, setLevel] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isAvailable, setIsAvailable] = useState(false)
    const [name, setName] = useState('')
    const [mode, setMode] = useState(true)

    // check if email valid, then check if it exists

    useEffect(() => {
        if(validateEmail(email)) {
            axios.get('https://ulangkajiapis.azurewebsites.net/auth/userexists?a=' + email).then(res => {

                let output = res.data

                if(output.isUser) {
                    setIsAvailable(false)
                } else {
                    setIsAvailable(true)
                }

            }).catch(err => {})
        } else {
            setIsAvailable(false)
        }
    }, [email])


    function login() {
        setIsLoading(true)
        axios.get('https://ulangkajiapis.azurewebsites.net/auth/login', {
            params:{
                a: email,
                b: password
            }
        }).then(res => {

            window.localStorage.setItem('access', res.data.token)

            axios.defaults.headers.common['Authorization'] = res.data.token;
            history.push('/dash')
        }).catch(err => {
            alert('Log masuk gagal. Cuba semula')
        }).finally(() => {
            setIsLoading(false)
        }) 
    }

    function create() {
        const payload = {
            "email": email,
            "fullname":name,
            "lvl": level,
            "accounttype": 'student',
            "b": password
        }

        axios.post('https://ulangkajiapis.azurewebsites.net/users', payload).then(res => {

            alert('Akaun anda telah didaftarkan. Anda akan menerima emel pengesahan dalam masa terdekat.')

            window.localStorage.setItem('access', res.data.token)

            axios.defaults.headers.common['Authorization'] = res.data.token;

            setMode(!mode)

        }).catch(err => {
            alert('Maaf pendaftaran gagal. Sila cuba semula.')
        })
    }

    function submit() {

        setIsLoading(true)

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': query.get('t')
            },
            body: JSON.stringify({
                b: password
            })
        }

        fetch('https://ulangkajiapis.azurewebsites.net/auth/reset', config).then((res) => res.status).then(status => {
            // console.log(status === 401)
            if(status !== 200) {
                alert('Permintaan gagal. Sila cuba sekali lagi.')
            } else {
                alert('Password berjaya ditukar. Sila log masuk semula dalam aplikasi.')
                history.push('/')
            }

        }).catch(err => {
            
        }).finally(() => setIsLoading(false)) 

    }

    function flipMode() {
        setMode(!mode)
    }

    return(
        <>
        <Flex flexWrap={'wrap'}>
            <Box width={[0, 0.33]} order={[1, 0]}>

            </Box>
            <Box width={[1, 0.33]} order={[0, 1]} style={{
                            backgroundImage: `url(${doodle})`,
                            backgroundPosition:'center center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '100vh'
                            
                            
                        }}>
                {/* <h1>Password Reset Page</h1> */}
                <img src={logo} style={{width: '100%', backgroundColor:'#FFFFFF70'}} />

                <br />

                <div style={{padding: '24px'}}>

                    <br />

                    {/* mode is true for login, false for signup */}

                    {mode ? (
                        <Card>

                            <h1>Log Masuk</h1>

                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Emel</h4>
                            <InputGroup id="text-input" placeholder="Emel" type={'text'} value={email} onChange={e => setEmail(e.target.value)}/>
                            
                            <br />
                            
    
                            <h4 style={{paddingBottom: '12px'}}>Password</h4>
                            <InputGroup id="text-input" placeholder="Password" type={'password'} value={password} onChange={e => setPassword(e.target.value)}/>
                            
                            <br />

                            <Button loading={isLoading} fill={true} intent={'primary'} large={true} onClick={() => {login()}} text={'Log Masuk'} disabled={email == '' || password == ''}></Button>

                            <Button minimal={true} fill={true} onClick={() => {flipMode()}} text={'Pengguna Baru?'}></Button>

                        </Card>
                    ) : (
                        <Card>

                            <h1>Pengguna Baru</h1>

                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Emel</h4>
                            <InputGroup id="text-input" placeholder="Emel" type={'text'} value={email} onChange={e => setEmail(e.target.value)}/>
                            {!isAvailable && (<h5 style={{color: 'red'}}>Emel tidak sah / Akaun sudah wujud</h5>)}
                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Nama</h4>
                            <InputGroup id="text-input" placeholder="Emel" type={'text'} value={name} onChange={e => setName(e.target.value)}/>

                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Password</h4>
                            <InputGroup id="text-input" placeholder="Password" type={'password'} value={password} onChange={e => setPassword(e.target.value)}/>

                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Sahkan Password</h4>
                            <InputGroup id="text-input" placeholder="Password" type={'password'} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>

                            <br />

                            <h4 style={{paddingBottom: '12px'}}>Tahap</h4>
                            <HTMLSelect fill={true} value={level} onChange={e => setLevel(e.target.value)}>
                                
                                {Object.keys(levels).map(lvl => {
                                    return <option value={level}>{levels[lvl]}</option>
                                })}
                                
                                
                            </HTMLSelect>

                            <br />

                            <br />

                            <Button 
                                loading={isLoading} 
                                fill={true} 
                                onClick={() => {create()}} text={'Daftar'} 
                                intent={'primary'}
                                large={true}
                                disabled={email == '' || !email || password == '' || !password || confirmPassword !== password || !isAvailable}>

                            </Button>

                            <Button minimal={true} fill={true} onClick={() => {flipMode()}} text={'Log Masuk?'}></Button>

                        </Card>
                    )}
                

                </div>

                
            </Box>
        </Flex>
           
        
        </>
    )
}

export default Login