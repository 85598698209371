import React from "react";

import { Flex, Box } from 'rebass'

import {
    useHistory
  } from "react-router-dom";

import { Button } from "@blueprintjs/core";

import logo from '../img/logo.png'
import ambience1 from '../img/student_photo_1.jpg'

import icon1 from '../img/icons/camera-3-line.png'
import icon2 from '../img/icons/question-line.png'
import icon3 from '../img/icons/discuss-line.png'

const Web = props => {

    const history = useHistory() 

    return (
    <>

        <div style={{paddingLeft: '5vw'}}>
            <img src={logo} className={'mainlogo'} />
        </div>

        <Flex flexWrap='wrap' >
            <Box width={[ 1, 1,1,0.6 ]}>

                <div style={{paddingLeft: '5vw', paddingTop: '5vh', paddingRight: '5vw',}}>
                    {/* <h1 className={'headline'} style={{marginTop: '12px', marginBottom: '18px', color: '#06B6D4'}}>
                        <b>Belajar Dengan Mudah!</b>
                    </h1> */}

                    <div style={{
                        paddingRight: '0vw'
                    }}>

                        <h2 style={{marginTop: '6px', marginBottom: '12px', color:'black'}}>
                            Buntu? Ingin bertanya, tetapi tiada siapa? <br /> <br />

                            Jangan bimbang, Ulangkaji sedia membantu
                        </h2>
                    </div>

                <div style={{paddingTop: '2vh'}}>
                    


                    <br />

                    <Flex>
                        <Box width={.15}>
                            <img src={icon1} style={{width: '48px', color: '#22D3EE'}} />
                        </Box>
                        <Box width={.85}>
                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Snap
                            </h2>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Tangkap gambar soalan anda
                            </p>
                        </Box>
                    </Flex>

                    <Flex>
                        <Box width={.15}>
                            <img src={icon2} style={{width: '48px', color: '#22D3EE'}} />
                        </Box>
                        <Box width={.85}>
                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Tanya
                            </h2>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Nyatakan apa yang anda keliru tentang soalan itu
                            </p>
                        </Box>
                    </Flex>

                    <Flex>
                        <Box width={.15}>
                            <img src={icon3} style={{width: '48px', color: '#22D3EE'}} />
                        </Box>
                        <Box width={.85}>
                            <h2 style={{marginTop: '12px', marginBottom: '12px', color: '#06B6D4'}}>
                                Belajar
                            </h2>
                            <p style={{backgroundColor:'#ffffff80'}}>
                                Belajar daripada jawapan, berserta jalan kerja yang disediakan oleh tutor
                            </p>
                        </Box>
                    </Flex>

                    <br />

                    <br />

                    <div style={{textAlign:'center'}}>
                        <Button fill={true} large={true}  text={<h3><b>Mula</b></h3>} intent={'primary'} onClick={() => history.push('/ask')} />
                        <Button fill={true} large={true}  text={<h3><b>Laman Utama</b></h3>} minimal={true} onClick={() => history.push('/')} />
                    </div>


                    



                </div>



                </div>

            </Box>

            <Box width={[ 1, 1,1,0.4 ]} 
             style={{padding: '24px'}}
            >
                <img src={ambience1} style={{width: '100%'}}/>

            </Box>
        </Flex>

    </>
    );
}

export default Web