import React, {useState, useEffect, useRef} from 'react'
import {Flex, Box} from 'rebass'
import { H1, Card, Elevation, Callout, InputGroup, H5, Button, Drawer, Tag, Position, DrawerSize } from '@blueprintjs/core'
import logo from '../../img/logo.png'
import Slider from 'react-slick'

import SearchImage from '../../img/search.png'
import TeachingImage from '../../img/teaching.png'
import LogoutImage from '../../img/logout.png'

import {
    useLocation,
    useHistory
  } from "react-router-dom";
import Answer from './answer';
import axios from 'axios';

import levels from '../../utils/levels.json'
import subjects from '../../utils/subjects.json'

import ambience1 from '../../img/learning.png'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};



const Soalan = props => {

    let query = useQuery();
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [previewAnswer, setPreviewAnswer] = useState(false)

    const [questions, setQuestions] = useState([])

    const [selectedQuestionId, setSelectedQuestionId] = useState('')

    const [displayName, setDisplayName] = useState('')

    const slider = useRef()

    useEffect(() => {

        const devToken = window.localStorage.getItem('access')

        setDisplayName(parseJwt(devToken).fullname)

        axios.get('https://ulangkajiapis.azurewebsites.net/questions/latest').then(res => {
            
            // setIsLoading(false)
            // setPage(page + 1)

            if(questions.length > 0) {
                setQuestions([...questions, ...res.data])
            } else {
                setQuestions(res.data)
            }
            

            if(res.data.length < 10) {
                // setNoMore(true)
            }

        }).catch(err => {

            // setIsLoading(false)

        })
    }, [])

    function submit() {

        setIsLoading(true)

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': query.get('t')
            },
            body: JSON.stringify({
                b: password
            })
        }

        fetch('https://ulangkajiapis.azurewebsites.net/auth/reset', config).then((res) => res.status).then(status => {
            // console.log(status === 401)
            if(status !== 200) {
                alert('Permintaan gagal. Sila cuba sekali lagi.')
            } else {
                alert('Password berjaya ditukar. Sila log masuk semula dalam aplikasi.')
                history.push('/')
            }

        }).catch(err => {
            
        }).finally(() => setIsLoading(false)) 

    }

    function logout() {
        axios.defaults.headers.common['Authorization'] = null
        history.push('/')
    }

    return(
        <>
        <Flex flexWrap={'wrap'}>
            <Box width={[0, 0.3]} order={[1, 0]}>
                

            </Box>
            <Box width={[1, 0.4]} order={[0, 1]}>
                {/* <h1>Password Reset Page</h1> */}
                {/* <img src={logo} style={{width: '100%'}} /> */}


                <div style={{padding: '24px'}}>
                    

                    <Card 
                        elevation={2}
                        style={{
                            backgroundImage: `url(${ambience1})`,
                            backgroundPosition:'right center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            minHeight: '25vh',
                            borderRadius: '12px'
                            
                        }}>
                        <h1>Hello <b>{displayName}</b></h1>
                    </Card>

                    <br />
                    <br />

                    <Flex>
                        <Box width={.48}>
                            <Card style={styles.roundedCard} elevation={2} interactive={true} onClick={() => {history.push('/web')}}>
                                <div 
                                    style={{
                                        backgroundImage: `url(${SearchImage})`,
                                        backgroundPosition:'center center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        minHeight: '25vh'
                                    
                                }}></div>

                                <h2 style={{
                                    padding: '12px',
                                    textAlign: 'center'
                                }}>Soalan baru</h2>
                            </Card>
                        </Box>
                        <Box width={.04}></Box>
                        <Box width={.48}>
                            <Card style={styles.roundedCard} elevation={2} interactive={true} onClick={() => {history.push('/search')}}>
                                <div 
                                    style={{
                                        backgroundImage: `url(${TeachingImage})`,
                                        backgroundPosition:'center center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        minHeight: '25vh'
                                    
                                }}></div>
                                <h2 style={{
                                    padding: '12px',
                                    textAlign: 'center'
                                }}>Kembara</h2>
                            </Card>
                        </Box>
                    </Flex>
                    <br />

                    <Card style={styles.roundedCard} elevation={2}>

                        <div >

                            <Flex style={{
                                padding: '12px'
                            }}>
                                <Box width={.7}>
                                    <h2>Soalan-soalan Anda</h2>
                                </Box>
                                <Box width={.2} >
                                    <h3 style={{textAlign: 'right'}}><a href="/soalan">Lihat Semua</a></h3>
                                </Box>
                            </Flex>

                            <hr />

                            {questions.map((question, index) => {
                                return(
                                    <>
                                        <div 
                                            style={{cursor: 'pointer', padding: '12px'}}                                        
                                            onClick={() => {
                                            setPreviewAnswer(true)
                                            setSelectedQuestionId(question.id)
                                        }}>
                                            <h3>{question.body}</h3>
                                            <Tag large={true} style={{margin: '4px'}}>{subjects[question.subj]}</Tag>
                                            <Tag large={true} style={{margin: '4px'}}>{levels[question.lvl]}</Tag>
                                        </div>

                                        {questions.length-1 != index ? <hr /> : <></> }

                                    <br />
                                    </>
                                )
                            })}

                        </div>
                    </Card>

                    <br />

                    <br />

                    {/* <Card style={styles.roundedCard} elevation={2}>

                        <div style={{
                            padding: '24px'
                        }}>

                            <Flex>
                                <Box width={1}>
                                    <h2>Bahan Bacaan Lanjutan</h2>
                                </Box>
                            </Flex>

                            <br />

                            <div 
                                style={{cursor: 'pointer'}}                                        
                                onClick={() => {
                            }}>
                                <h3><a href="">Kelas Online</a></h3>
                                <Tag large={true} style={{margin: '4px'}}>Article</Tag>
                            </div>

                            <hr />

                            <div 
                                style={{cursor: 'pointer'}}                                        
                                onClick={() => {
                                // setPreviewAnswer(true)
                                // setSelectedQuestionId(question.id)
                            }}>
                                <h3>{'Kelas Online'}</h3>
                                <Tag large={true} style={{margin: '4px'}}>Article</Tag>
                            </div>

                        </div>
                        </Card>

                        <br /> */}

                        <Card style={styles.roundedCard} elevation={2} interactive={true} onClick={() => {logout()}}>
                            <div 
                                style={{
                                    backgroundImage: `url(${LogoutImage})`,
                                    backgroundPosition:'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    minHeight: '20vh'
                                
                            }}></div>

                            <h2 style={{
                                padding: '12px',
                                textAlign: 'center'
                            }}>Log Keluar</h2>
                        </Card>

                        <br />

                        <img src={logo} style={{width: '33%'}} />

                
                </div>

                
            </Box>
        </Flex>
        <Drawer
            isOpen={previewAnswer}
            position={Position.BOTTOM}
            size={'95%'}
            style={{height: '100vh', overflowY:'scroll'}}
            onClose={() => setPreviewAnswer(false)}
            >    
            {previewAnswer ? <Answer questionid={selectedQuestionId} onClose={() => setPreviewAnswer(false)} /> : <></>}
            

        </Drawer>
        
        </>
    )
}

const styles = {
    roundedCard : {
        borderRadius: '12px',
        padding: '0px'
    }
}

var settings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true
  };

export default Soalan