import React, {useState, useEffect, useRef} from 'react'
import {Flex, Box} from 'rebass'
import { H1, Card, Elevation, Callout, InputGroup, H5, Button, Drawer, Tag, Position, DrawerSize, AnchorButton } from '@blueprintjs/core'
import logo from '../../img/logo.png'
import Slider from 'react-slick'

import {
    useLocation,
    useHistory
  } from "react-router-dom";
import Answer from './answer';
import axios from 'axios';

import levels from '../../utils/levels.json'
import subjects from '../../utils/subjects.json'

import ambience1 from '../../img/learning.png'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};



const Soalan = props => {

    let query = useQuery();
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [previewAnswer, setPreviewAnswer] = useState(false)

    const [questions, setQuestions] = useState([])

    const [selectedQuestionId, setSelectedQuestionId] = useState('')

    const [displayName, setDisplayName] = useState('')

    const slider = useRef()

    useEffect(() => {

        const devToken = window.localStorage.getItem('access')

        setDisplayName(parseJwt(devToken).fullname)

        axios.get('https://ulangkajiapis.azurewebsites.net/questions/s?pg=' + 0 + '&answered=' + false).then(res => {
            
            // setIsLoading(false)
            // setPage(page + 1)

            if(questions.length > 0) {
                setQuestions([...questions, ...res.data])
            } else {
                setQuestions(res.data)
            }
            

            if(res.data.length < 10) {
                // setNoMore(true)
            }

        }).catch(err => {

            // setIsLoading(false)

        })
    }, [])

    function submit() {

        setIsLoading(true)

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': query.get('t')
            },
            body: JSON.stringify({
                b: password
            })
        }

        fetch('https://ulangkajiapis.azurewebsites.net/auth/reset', config).then((res) => res.status).then(status => {
            // console.log(status === 401)
            if(status !== 200) {
                alert('Permintaan gagal. Sila cuba sekali lagi.')
            } else {
                alert('Password berjaya ditukar. Sila log masuk semula dalam aplikasi.')
                history.push('/')
            }

        }).catch(err => {
            
        }).finally(() => setIsLoading(false)) 

    }

    function logout() {
        axios.defaults.headers.common['Authorization'] = null
        history.push('/')
    }

    return(
        <>
        <Flex flexWrap={'wrap'}>
            <Box width={[0, 0.3]} order={[1, 0]}>
                

            </Box>
            <Box width={[1, 0.4]} order={[0, 1]}>
                <div style={{paddingTop: '12px'}}>

                    <Flex>
                        <Box width={.2}>
                            <AnchorButton large={true} minimal={true} icon={'arrow-left'} onClick={() => history.goBack() } />
                        </Box>
                        <Box width={.8}>
                            <h3>Soalan - soalan anda</h3>
                        </Box>
                    </Flex>
                    

                    {questions.map((question, index) => {
                        return(
                            <>
                                <div 
                                    style={{cursor: 'pointer', padding: '12px'}}                                        
                                    onClick={() => {
                                    setPreviewAnswer(true)
                                    setSelectedQuestionId(question.id)
                                }}>
                                    <h3>{question.body}</h3>
                                    <Tag large={true} style={{margin: '4px'}}>{subjects[question.subj]}</Tag>
                                    <Tag large={true} style={{margin: '4px'}}>{levels[question.lvl]}</Tag>
                                </div>

                                {questions.length-1 != index ? <hr /> : <></> }

                            <br />
                            </>
                        )
                    })}

                    <br />
                
                </div>

                
            </Box>
        </Flex>
        <Drawer
            isOpen={previewAnswer}
            position={Position.BOTTOM}
            size={'95%'}
            style={{height: '100vh', overflowY:'scroll'}}
            onClose={() => setPreviewAnswer(false)}
            >    
            {previewAnswer ? <Answer questionid={selectedQuestionId} onClose={() => setPreviewAnswer(false)} /> : <></>}
            

        </Drawer>
        
        </>
    )
}

const styles = {
    roundedCard : {
        borderRadius: '12px',
        padding: '0px'
    }
}

var settings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true
  };

export default Soalan