import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ScrollToTop from './utils/scrollTop';

import DOMPurify from "dompurify";

import {
  InputGroup,
  Button,
  Card,
  H5,
  H6,
  Elevation,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Drawer,
  DrawerSize
} from "@blueprintjs/core"

import Main from './pages/main';
import Terms from './pages/terms.js'
import Reset from './pages/reset.js'
import Privacy from './pages/privacy.js'
import About from './pages/about.js'
import {Flex, Box} from 'rebass'
import Web from './pages/web';
import Webask from './pages/web_ask';
import Webend from './pages/web_end';
import Answer from './pages/answer';
import Search from './pages/search';

import Dashboard from './pages/app/dashboard';
import Soalan from './pages/app/soalan';
import Login from './pages/app/login'

function App() {

  const [latest, setLatest] = useState([{
    text: ''
  }])

  const [viewAnswer, setViewAnswer] = useState(false)

  return (
    <div style={{
      // paddingLeft: '20vw'
    }}>

      <Router>
        <ScrollToTop />
        <Switch>

          <Route exact path="/">
              <Main />
          </Route>

          <Route path="/login" >
              <Login />
            </Route>
            
          <Route path="/dash" >
              <Dashboard />
            </Route>

            <Route path="/soalan" >
              <Soalan />
            </Route>

            <Route path="/tentang">
              <About />
            </Route>
            <Route path="/reset">
              <Reset />
            </Route>
            <Route path="/terma">
              <Terms />
            </Route>
            <Route path="/privasi">
              <Privacy />
            </Route>
            <Route path="/web">
              <Web />
            </Route>
            <Route path="/ask">
              <Webask />
            </Route>
            <Route path="/end">
              <Webend />
            </Route>
            <Route path="/answer">
              <Answer />
            </Route>
            <Route path="/search">
              <Search />
            </Route>

            <Route path="/dashboard" >
              <Dashboard />
            </Route>


          </Switch>

        <div>
          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
          </nav> */}

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}

        </div>
      </Router>
    {/* <Navbar>
      <NavbarGroup align={'left'}>
        <NavbarHeading>UlangKaji</NavbarHeading>
      </NavbarGroup>
    </Navbar>
    <br/>
    <div style={{padding: '12px'}}>
      <h2>Ulangkaji Online bersama kami!</h2>
    </div>
    <div style={{padding:'12px'}}>
      <InputGroup
          large={true}
          placeholder={'Cari Jawapan'}
          type={"text"}
          rightElement={<Button icon={'search'} minimal={true}/>}
        />

      <br />
      <Card
        elevation={Elevation.ONE}
        onClick={() => setViewAnswer(true)}
        >
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize('<h4>Pengkamiran y = x<sup>2</sup></h4>')}}></div>
        <div style={{textAlign:'right'}}><p>30/6/2021</p></div>
      </Card>
      <br />
      <Card
        elevation={Elevation.ONE}>
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize('<h4>Pengkamiran y = x<sup>2</sup></h4>')}}></div>
        <div style={{textAlign:'right'}}><p>30/6/2021</p></div>
      </Card>
    </div>

    <Drawer position={'bottom'} size={DrawerSize.LARGE} isOpen={viewAnswer} onClose={() => setViewAnswer(false)}>

    </Drawer> */}

    </div>

  );
}

export default App;
